<script lang="ts" setup>
import VLandingHeader from "~/containers/VLanding/VLandingHeader.vue";
import VLandingOnline from "~/containers/VLanding/VLandingOnline.vue";
import VLandingCards from "~/containers/VLanding/VLandingCards.vue";
import VLandingTutorial from "~/containers/VLanding/VLandingTutorial.vue";
import VLandingQuestions from "~/containers/VLanding/VLandingQuestions.vue";
import { getMobileOS } from "~/utils/clientInfo";
import type { IMobileOS } from "~/utils/clientInfo";
import VLandingQr from "~/containers/VLanding/VLandingQr.vue";
import VLandingFooter from "~/containers/VLanding/VLandingFooter.vue";

useHead({
  title: "Активировать или зарегистрировать карту Магнит",
  meta: [
    {
      name: "description",
      content:
        "Вход в личный кабинет Мой Магнит Онлайн. Активация и регистрация карты Магнит. Бонусы и Магнитики. Официальный сайт",
    },
    {
      name: "theme-color",
      content: "#dceaff",
    },
  ],
});

const platformRef = ref<"iOS" | "Android">("Android");

const onSectionClick = (value: "iOS" | "Android") => {
  platformRef.value = value;
};

const OS = ref<IMobileOS>("other");

onMounted(() => {
  OS.value = getMobileOS();
  if (OS.value === "ios") {
    platformRef.value = "iOS";
  }
});
</script>

<template>
  <main class="page-main" :class="`os-${OS}`">
    <VLandingHeader @section-click="onSectionClick" />
    <VLandingOnline />
    <VLandingCards />
    <VLandingTutorial v-model:platform="platformRef" />
    <VLandingQuestions />
    <VLandingFooter />
    <VLandingQr />
  </main>
</template>

<style lang="postcss">
.page-main {
  min-width: 360px;
  width: 100%;
}
</style>
