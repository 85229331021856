<script lang="ts" setup>
import { useElementVisibility } from "@vueuse/core";
import { useRouter } from "vue-router";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import VText from "@magnit/core/src/components/VText/VText.vue";
import VHidden from "@magnit/core/src/components/VHidden/VHidden.vue";
import { Routes } from "~/utils/routes";

type IVLandingHeaderSections = "benefits" | "online" | "tutorial";

const emit = defineEmits<{
  sectionClick: [value: "iOS" | "Android"];
}>();

const router = useRouter();
const { send } = useAnalytics();
const pwaStore = usePwaStore();
const platformStore = usePlatformStore();

const headerRef = ref<null | HTMLElement>(null);
const headerRefIsVisible = useElementVisibility(headerRef);
const isPwa = computed(() => platformStore.platformInfo.pwa);

const onScrollIntoView = (section: IVLandingHeaderSections) => {
  const options: ScrollIntoViewOptions = {
    block: "start",
    behavior: "smooth",
  };

  if (section === "benefits") {
    const benefitsEl = document.getElementById("benefits");
    benefitsEl?.scrollIntoView(options);
  }

  if (section === "online") {
    const cardsEl = document.getElementById("online");
    cardsEl?.scrollIntoView(options);
  }

  if (section === "tutorial") {
    const tutorialEl = document.getElementById("tutorial");
    tutorialEl?.scrollIntoView(options);
  }
};

const onScrollIntoViewTutorial = (value: "iOS" | "Android", title: string) => {
  const tutorialEl = document.getElementById("tutorial");

  tutorialEl?.scrollIntoView({
    block: "start",
    behavior: "smooth",
  });

  emit("sectionClick", value);

  send("LandingPage:Apps:Click", {
    title,
  });
};

const onHeaderClick = (section: IVLandingHeaderSections, title: string) => {
  onScrollIntoView(section);
  send("LandingPage:Main:Header:Click", {
    title,
    target_url: `#${section}`,
  });
};

const onLoginHeaderClick = () => {
  send("LandingPage:Main:Header:Click", {
    title: "Войти",
    target_url: Routes.Login,
  });
  router.push(Routes.Login);
};

const onLoginButtonClick = () => {
  send("LandingPage:Main:Button:Click", {
    title: "Войти",
    target_url: Routes.Login,
  });
  router.push(Routes.Login);
};

const onOpenPwaClick = () => {
  if (!isPwa.value && typeof window !== "undefined") {
    window.open(pwaStore.DEFAULT_PWA_LINK, "_blank");
  }
};

const onInstallPwaClick = () => {
  pwaStore.showPrompt();
};

watch(headerRefIsVisible, (newHeaderRefIsVisible) => {
  if (newHeaderRefIsVisible) {
    send("LandingPage:Main:View");
  }
});

const profits = [
  "Открывайте карту в 2 клика",
  "Следите за балансом бонусов",
  "Получайте больше бонусов и скидок, чем с пластиковой картой",
];
</script>

<template>
  <header ref="headerRef" class="header">
    <div class="header-wrap">
      <div class="header__nav">
        <img
          class="header__nav__img"
          src="~/assets/svg/pages/landing/magnit-logo.svg?url"
          alt="Магнит"
        >
        <nav class="header__nav__container">
          <span class="header__nav-item" @click="onLoginHeaderClick"> Войти </span>
          <span class="header__nav-item" @click="onHeaderClick('online', 'Карта Магнит')">
            Карта Магнит Плюс
          </span>
          <VHidden :hide="{ xs: true, ml: false }">
            <span class="header__nav-item" @click="onHeaderClick('tutorial', 'Как установить')">
              Как установить
            </span>
          </VHidden>
        </nav>
      </div>

      <div class="header__content">
        <div class="header__content__text">
          <VText
            tag="h1"
            :font="{
              xs: 'headline-large',
              m: 'display-medium',
              l: 'display-large',
            }"
          >
            Карта Магнит Плюс в&nbsp;телефоне
          </VText>
          <VText tag="h3" :font="{ xs: 'title-large', l: 'headline-large' }">
            Добавьте иконку Магнит Онлайн&nbsp;на&nbsp;рабочий&nbsp;стол
          </VText>
          <ul class="header__content__text__list">
            <VText
              v-for="(item, i) in profits"
              :key="i"
              tag="li"
              :font="{ xs: 'body-large-accent', l: 'headline-small' }"
              class="header__content__text__list__item"
            >
              {{ item }}
            </VText>
          </ul>
        </div>
        <div class="header__content__button">
          <VButton class="header__content__button__login" @click="onLoginButtonClick">
            Войти
          </VButton>
          <VButton
            theme="invert"
            @click="onScrollIntoViewTutorial('Android', 'Добавить на Android')"
          >
            <span class="header__content__button__item">
              <img src="~/assets/svg/pages/landing/android-icon.svg?url" alt="Android">
              Добавить на Android
            </span>
          </VButton>
          <VButton theme="invert" @click="onScrollIntoViewTutorial('iOS', 'Добавить на IOS')">
            <span class="header__content__button__item">
              <img src="~/assets/svg/pages/landing/apple-icon.svg?url" alt="Apple">
              Добавить на IOS
            </span>
          </VButton>
        </div>
      </div>
      <div class="header__content__app-box" />
    </div>
    <div class="header__button">
      <VButton v-if="!isPwa && pwaStore.state.appInstalled" full-width @click="onOpenPwaClick">
        Открыть
      </VButton>
      <VButton
        v-if="!isPwa && !pwaStore.state.appInstalled && pwaStore.state.installPromptBannerOpen"
        full-width
        :loading="pwaStore.state.installInProgress"
        @click="onInstallPwaClick"
      >
        Добавить на рабочий стол
      </VButton>
      <VButton
        v-if="isPwa || (!pwaStore.state.appInstalled && !pwaStore.state.installPromptBannerOpen)"
        full-width
        @click="onLoginButtonClick"
      >
        Войти
      </VButton>
    </div>
  </header>
</template>

<style lang="postcss">
.header {
  font-family: var(--pl-font-family);
  overflow: hidden;
  position: relative;
  background: linear-gradient(260deg, rgb(161 199 255 / 70%), #f3f8ff);

  @media (--pl-viewport-from-l) {
    padding: 0 var(--pl-unit-x8) 110px;
    background: linear-gradient(
      45deg,
      #f3f8ff 0,
      rgb(161 199 255 / 70%) 65%,
      rgb(161 199 255 / 70%) 70%,
      #f3f8ff 100%
    );
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 70px;
    background: linear-gradient(to bottom, transparent, white);
  }

  &-wrap {
    position: relative;
    z-index: var(--pl-z-index-level2);
  }

  &__button {
    bottom: 16px;
    display: block;
    position: fixed;
    width: 100%;
    padding: 0 20px;
    z-index: var(--pl-z-index-level3);

    @media (--pl-viewport-from-ml) {
      display: none;
    }
  }

  &__content {
    padding: 0 20px;

    @media (--pl-viewport-from-ml) {
      position: relative;
      padding: 0 32px;
    }

    @media (--pl-viewport-from-l) {
      margin: 0 auto;
      max-width: 1200px;
      padding: 0;
    }
  }

  &__content__app-box {
    max-width: 1200px;
    background-image: url("~/assets/illustrations/landing/sections/header/plane.webp");
    height: 288px;
    background-position: center;
    background-size: 522px;
    background-repeat: no-repeat;
    margin: 0 auto 48px;
    position: relative;

    @media (--pl-viewport-from-m) {
      margin-top: -16px;
      padding: 0 24px;
    }

    @media (--pl-viewport-from-ml) {
      margin-top: 44px;
      background-position: calc(50% - 42px) 0;
      background-size: 532px;
    }

    @media (--pl-viewport-from-l) {
      padding: 0;
      position: absolute;
      left: 440px;
      top: 300px;
      margin: 0;
      width: 512px;
      background-size: 100%;
      background-position: center;
    }

    @media (--pl-viewport-from-xl) {
      top: 270px;
      width: 612px;
      height: 330px;
      left: calc(50% + 50px);
    }
  }

  &__content__button {
    display: none;

    @media (--pl-viewport-from-ml) {
      display: flex;
      gap: 20px;
      margin-top: 24px;
      position: relative;
      z-index: 2;
    }

    @media (--pl-viewport-from-l) {
      margin-top: 40px;
    }

    @media (--pl-viewport-from-xl) {
      margin-top: 64px;
    }
  }

  &__content__button__item {
    @media (--pl-viewport-from-ml) {
      display: flex;
    }

    img {
      @media (--pl-viewport-from-ml) {
        margin-right: 8px;
      }
    }
  }

  &__content__button__login {
    @media (--pl-viewport-from-ml) {
      width: 106px;
    }

    @media (--pl-viewport-from-l) {
      width: 160px;
    }
  }

  &__content__text {
    display: flex;
    flex-direction: column;
    gap: var(--pl-unit-x3);
    margin-top: var(--pl-unit-x4);
    position: relative;
    z-index: 1;
    color: var(--pl-text-primary);
    font-family: var(--pl-font-family);
    max-width: 520px;

    @media (--pl-viewport-from-ml) {
      max-width: 100%;
    }

    @media (--pl-viewport-from-l) {
      margin-top: 82px;
      max-width: 520px;
    }

    @media (--pl-viewport-from-xl) {
      margin-top: 88px;
    }

    h1 {
      margin-bottom: 8px;
    }

    h3 {
      max-width: 450px;
      margin-top: var(--pl-unit-x2);

      @media (--pl-viewport-from-ml) {
        max-width: 100%;
      }
    }
  }

  &__content__text__list {
    margin-top: var(--pl-unit-x3);
    max-width: 480px;
    display: flex;
    flex-direction: column;
    gap: var(--pl-unit-x3);

    @media (--pl-viewport-from-ml) {
      max-width: 100%;
    }

    @media (--pl-viewport-from-l) {
      margin-top: 40px;
      gap: var(--pl-unit-x4);
    }
  }

  &__content__text__list__item {
    padding-left: 20px;
    position: relative;

    @media (--pl-viewport-from-l) {
      padding-left: 50px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      background-color: var(--pl-text-primary);
      border-radius: 50%;
      content: "";
      height: 8px;
      left: 0;
      position: absolute;
      top: 8px;
      width: 8px;

      @media (--pl-viewport-from-l) {
        height: 12px;
        top: 10px;
        width: 12px;
      }
    }
  }

  &__nav {
    border-bottom: 1px solid var(--pl-border-primary-default);
    padding: 24px 0 16px;
    margin: 0 20px;
    text-align: center;

    @media (--pl-viewport-from-m) {
      text-align: left;
    }

    @media (--pl-viewport-from-ml) {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 32px 0 16px;
      margin: 0 32px;
    }

    @media (--pl-viewport-from-l) {
      margin: 0 auto;
      max-width: 1200px;
      padding: 40px 0 36px;
    }
  }

  &__nav__img {
    height: 24px;

    @media (--pl-viewport-from-l) {
      height: var(--pl-unit-x8);
    }
  }

  &__nav__container {
    display: flex;
    justify-content: flex-start;
    margin-top: 16px;

    @media (--pl-viewport-from-ml) {
      margin-top: 0;
    }
  }

  &__nav-item {
    color: var(--pl-text-primary);
    font: var(--pl-font-body-large-accent);
    cursor: pointer;
    margin-right: 16px;
    text-decoration: none;
    white-space: nowrap;

    @media (--pl-viewport-from-l) {
      margin-right: 40px;
    }

    &:last-child {
      margin-right: 0;

      @media (--pl-viewport-from-l) {
        margin-right: 0;
      }
    }

    &:nth-child(3) {
      .os-android & {
        display: none;

        @media (--pl-viewport-from-ml) {
          display: inline-flex;
        }

        @media (--pl-viewport-from-l) {
          display: block;
        }
      }
    }
  }
}
</style>
